.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
}

.sub_container{
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0 2rem 0 2rem;
}
.user_avatar_and_name{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.avatar{
    width:6rem !important;
    height:6rem !important;
}

.name .text{
    font-size: 1.6rem;
    font-family: 'Signika Negative',sans-serif ;
}

.subscriber_info{
    padding: 1rem;
    border-radius: 0.3rem;
    /* background-color:#5B4DC3; */
    background-color: #1F1C3A;
    color: white;
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.coin{
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    background-color: #FFCC5F;
    border: 2px solid #FE9E44;
    margin-right: 0.25rem;
}

.points{
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-family: 'Signika Negative',sans-serif;
}

.main_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 0 4rem 0 4rem;
    width: 100%;
}

.history_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    width: 40%;
    /* background-color: #6A5AE0; */
    /* background-color: #1F1C3A; */
    background-color: #000;
    border-radius: 0.3rem;
    box-shadow: 0 0.3rem 1rem rgba(0,0,0,0.3);
    cursor: pointer;
    transition: all 0.3s;
}

.history_container:active{
    transform: scale(1.1);
}

.history_icon_and_text{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.history_text_container h3{
    color:rgb(226 232 240);
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
    font-family: 'Signika Negative',sans-serif;
}

.arrow_container{
    width: 3rem;
    height:3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.logout_container{
    cursor: pointer;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    width: 40%;
    border: 0.1rem solid #1F1C3A;
    border-radius: 0.3rem;
    box-shadow: 0 0.3rem 1rem rgba(0,0,0,0.3);
    transition: all 0.3s;
}

.logout_text_container h3{
    color: #1F1C3A;
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
    font-family: 'Signika Negative',sans-serif;
}

.logout_container:active{
    transform: scale(1.1);
}   

@media screen and (max-width:900px){
    .sub_container{
    width: 90%;
    }
    .logout_container{
        width: 80%;
    }
    .history_container{
        width: 80%;
    }
}

@media screen and (max-width:550px){
    .sub_container{
    width: 95%;
    }
    .logout_container{
        width: 95%;
    }
    .history_container{
        width: 95%;
    }
}