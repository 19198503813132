.tabs_container{
    width: 100%;
    /* background-color: #9087E5; */
    background-color:#1F1C3A;
    padding: 0;
}
.tab{
    width: 25% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: white !important;
    font-size: 1.8rem !important;
    font-family: 'Signika Negative',sans-serif !important;
}

@media screen and (max-width:1000px) {
    .tab{
        font-size: 1.2rem !important;
    }
}

@media screen and (max-width:500px) {
    .tab{
        font-size: 1rem !important;
    }
}