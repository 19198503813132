.loadingContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.animationContainer{
    width: 30rem;
    height: 30rem;
}
.animation{
    width: 100%;
    height: 100%;
    object-fit: cover;
}