.popular_quiz_heading{
    width: 100%;
}
.popular_quiz_heading h1{
    text-align: center;
    font-size: 3rem;
    color:rgb(31,28,58);
    font-family: 'Signika Negative',sans-serif;
}

.popular_quiz_container{
    width: 100%;
    padding: 1rem 2rem 1rem 2rem;
}

.quiz_card{
    border-radius: 0.3rem;
    background-color: #1F1C3A;
    width:100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,0.4);
    overflow: hidden;
    cursor: pointer;
}

.quiz_image{
    width: 100%;
    height: 8rem;
}
.image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.text{
    font-size: 1.5rem;
    font-family: 'Signika Negative', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 200;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin: 0; 
    text-align: center;
    width: 100%;
}

@media screen and (max-width:480px){
    .quiz_image{
        height: 6rem;
    }
}