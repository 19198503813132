
.header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 10rem;
    /* padding: 0 3rem 0 3rem; */
}

.headerLogo{
    width: 25rem;
    height: 5rem;
    margin-left: -24px;
}
.logo{
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.search_icon{
    position: relative;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
/* background-color: #5B4DC3; */
background-color: #1F1C3A;
cursor:pointer;
}
.icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 2rem;
    color: white;
}