.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding:1rem 2rem 1rem 2rem;
    /* background-color: #6A5AE0; */
    background-color: #000;
    box-shadow:0 0.3rem 0.7rem rgba(0,0,0,0.4);
}

.image{
    width: 14rem;
    height: 4rem;
}
.image img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.close_icon{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  #1F1C3A;
}

.box_sub_container{
    margin: 0 20%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
@media only screen and (max-width: 900px) {
    .box_sub_container{
        margin: 0 5%;
    }
  }