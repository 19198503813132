.navigation_container{
    width:100%;
    height: 10rem;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.back_icon{
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(-5%,-50%);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(31,28,58);
    box-shadow:0 0.3rem 0.6rem rgba(0,0,0,0.4);
    cursor: pointer;
}

.text_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
}

.text_container h2{
    color:rgb(31,28,58);
    font-size: 4rem;
    font-weight:bold;
    font-family: 'Signika Negative',sans-serif;
}

@media screen and (max-width:500px){
    .text_container h2{
        color:rgb(31,28,58);
        font-size: 3rem;
        font-weight:bold;
        font-family: 'Signika Negative',sans-serif;
    }
}