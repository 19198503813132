.main_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap:1rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0.3rem 0.8rem rgba(0,0,0,0.4);
    /* box-shadow: 0 0.3rem 0.8rem rgba(255, 255, 255, 0.2); */
    padding: 0 0 1rem 0;
}
