.container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.container h3{
    width: 80%;
    font-size: 3rem;
    font-family: 'Signika Negative',sans-serif;
    color:rgb(31,28,58) ;
}