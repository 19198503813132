.modal_navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #5B4DC3;
    padding: 1rem;
}

.image_container{
    width: 10rem;
    height: 4rem;
}
.image{
    width: 100%;
    height:100%;
    object-fit: contain;
}

.close_icon{
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
}

.text{
    width: 100%;
}

.text h2{
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    color:#1E2947;
}

.buttons_container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.play_button{
    border: none;
    outline: none;
    width: 10rem;
    padding: 0.5rem;
    background-color: #5B4DC3;
    border-radius: 0.3rem;
    color: white;
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    transition: all 0.3s;
}

.close_button{
    border: 0.1rem solid #5B4DC3;
    width: 10rem;
    padding: 0.5rem;
    border-radius: 0.3rem;
    color: #1E2947;
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    transition: all 0.3s;
}

.play_button:hover{
    background-color: #6A5AE0;
}

.play_button:active{
    transform: scale(1.1);
}

.close_button:hover{
    background-color: #5B4DC3;
    color: white;
}

.close_button:active{
    transform: scale(1.1);
}