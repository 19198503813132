.quiz_grid{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.quiz_image_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    overflow: hidden;
}

.image{
    width: 20rem;
    height: 14rem;
    object-fit: contain;
}

.image_text{
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
}

.quiz_details_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    width:100%;
}
.quiz_question_container{
    width: 100%;
    border-radius:2rem;
    /* background-color: #6A5AE0; */
    background-color:#1F1C3A;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:2rem;
    flex-direction: row;
    padding: 1rem 2rem 1rem 2rem;
}

.icon_question{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #5B4DC3; */
    background-color: #1c1c229d;
}

.quiz_text h2{
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.2rem;
}

.quiz_point_container{
    width: 100%;
    border-radius:2rem;
    /* background-color: #6A5AE0; */
    background-color:#1F1C3A;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:2rem;
    flex-direction: row;
    padding: 1rem 2rem 1rem 2rem;
}

.icon_star{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #5B4DC3; */
    background-color: #1c1c229d;
}

.quiz_description{
    grid-column: span 2;
    width:100%;
}

.quiz_description_text{
    text-align: center;
    font-size: 1.5rem;
    color:#1E2947;
    font-family: "Lato",sans-serif;
}

.play_button_container{
    grid-column: span 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button{
    border: none;
    outline: none;
    padding: 1.5rem;
    width: 30rem;
    /* background-color: #5B4DC3; */
    background-color: #000;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.4);
    color: white;
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    transition: all 0.3s;
    cursor: pointer;
}

.button:hover{
    /* background-color: #6A5AE0; */
    background:linear-gradient(90deg,rgba(0,0,0,0.9) 0%,rgba(0,0,0,1));
}

.button:active{
    transform: scale(1.1);
}

@media screen and (max-width: 600px) {
    .quiz_grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    }
    .quiz_description{
        grid-column: span 2;
    }
}