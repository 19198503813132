.message_container{
    padding: 20px 10px 20px 10px;
    width: 270px;
    min-height: 50px;
    background-color: #1f1c3aae;
    border: 1px solid #252148;
    border-left: 3px solid #1F1C3A;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.text {
    font-size: 20px;
    color: #fff;
    font-family: 'Signika Negative',sans-serif !important;
    font-weight: 500;
    padding: 0;
    margin: 0;
}