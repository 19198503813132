.login_text .text{
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    text-align: center;
    width: 100%;
    color:#051621;
}

.container{
    display: grid;
    grid-template-columns:2fr 1fr;
    grid-area: 1rem;
    padding: 1rem;
}
.animation_container{
    order:2;
    width:30rem;
    height: 25rem;
}
.animation{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.login_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.form_signup{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem 0 1rem 0;
}

.input_group input{
    width: 25rem;
    height: 5rem;
    border: none;
    outline: none;
    /* border-bottom: .2rem solid rgba(64, 123,255, 0.5); */
    border-bottom: .2rem solid #0516218c;
    /* color: #407bff; */
    color: #051621;
    font-size: 1.6rem;
    padding-right:2rem;
    background-color: transparent;    
    font-family: 'Signika Negative',sans-serif;
}

.input_group input::placeholder{
    color: #051621;
    letter-spacing: .1rem;
    font-family: 'Signika Negative',sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
}

.input_group input:focus{
    border-color:#051621 ;
}

.input_group{
    position: relative;
}
.input_group i{
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
    font-size: 1.3rem;
    color: #051621;
}

.form_btn{
    width: 20rem;
    height: 4.5rem;
    border: none;
    border-radius: 5rem;
    background-color: #051621;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: .1rem;
    box-shadow: 0 0.5rem 1rem rgba(64, 123,255, 0.4);
    cursor: pointer;
}
.form_btn:active{
    transform: translateY(.2rem);
}

.form_btn:hover{
    background-color: #031018;
}

.form_link a{
    color: #051621;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width:700px){
    .container{
        grid-template-columns: 1fr;
        width: 100%;
    }
    .animation_container{
    order:0;
        width: 100%;
    }
    .input_group input{
        width: 30rem;
        height: 5rem;
    }
}