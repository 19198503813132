.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 2rem 2rem 2rem;
}

.time_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: 100%;
}
.time_display{
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
}

.avatars_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.avatar_1_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.avatar_2_container{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.avatar_1_data,.avatar_2_data{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.avatar_1_data p,.avatar_2_data p{
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
}

.avatar_1,.avatar_2{
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.avatar_1 img,.avatar_2 img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.swords{
    width:5rem;
    height: 5rem;
}
.swords img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.question_number_container{
    width:100%
}
.question_number_container h3{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
}

@media screen and (max-width:500px){
    .avatar_1_container{
        flex-direction: column;
    }
    
    .avatar_2_container{
        flex-direction:column;
    }
}