.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.animationContainer{
    width:30rem;
    height: 20rem;
}
.animation{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.result_container{
    width: 100%;
}
.result_text{
    text-align: center;
    color:#1F1C3A;
    font-size: 3rem;
    font-family: 'Signika Negative',sans-serif;
}

.totalScore_container{
    display: flex;
    justify-content:center;
    align-items: center;
    gap:1rem;
    padding: 1rem;
    width: 10rem;
    background-color: #1F1C3A;
    border-radius: 0.3rem;
    box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,0.4);
}

.coin{
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    background-color: #FFCC5F;
    border: 2px solid #FE9E44;
    margin-right: 0.25rem;
}

.points{
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
    color: #ffffff;
}

.matchScore_container{
    margin-top: 3rem;
    width: 80%;
    background-color: #1F1C3A;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
}

.finalScore_container{
    padding: 1rem 2rem 1rem 2rem;
    background-color: #5443ec;
}

.finalScore_container h3{
    color:white;
    font-size: 3rem;
    font-family: 'Signika Negative',sans-serif;
}

.matchScore_subContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.you_score_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.score_container{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5443ec41;
    box-shadow: 0 0.3rem 1rem rgba(0,0,0,0.4);
}
.score_container span{
    color: white;
    font-size: 4rem;
    font-family: 'Signika Negative',sans-serif;
}

.text h3{
    color: white;
    font-size: 2rem;
    font-family: 'Signika Negative',sans-serif;
}





.accordianContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}