.modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    background-color:white ;
    border: 0 solid #8c52ff;
    box-shadow: 0 0.3rem 0.8rem rgba(0,0,0,0.5);
    padding: 4rem;
}

.input_group input{
    width: 25rem;
    height: 5rem;
    border: none;
    outline: none;
    border-bottom: .2rem solid #0516218c;
    color: #051621;
    font-size: 1.6rem;
    padding-right:2rem;
    background-color: transparent;    
    font-family: 'Signika Negative',sans-serif;
}

.input_group input::placeholder{
    color: #051621;
    letter-spacing: .1rem;
    font-family: 'Signika Negative',sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
}

.input_group input:focus{
    border-color:#051621 ;
}

.game_title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.game_description{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.game_description_2{
    color: #969696;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container{
    width:100%;
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-top: 10px;
    cursor: pointer;
}
.image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.link_button{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:700px){
    .modal{
        width:450px;
    }
}

@media screen and (max-width:470px){
    .modal{
        width:350px;
    }
}