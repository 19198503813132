.container{
    width: 100%;
    padding: 0rem 30rem 3rem 30rem;
    position: relative;
    overflow: hidden;
}

.sub_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    overflow: hidden;
}

@media screen and (max-width:1200px) {
    .container{
        padding: 0rem 20rem 0 20rem;
    }
}

@media screen and (max-width:1100px) {
    .container{
        padding: 0rem 15rem 0 15rem;
    }
}

@media screen and (max-width:800px) {
    .container{
        padding: 0rem 3rem 0 3rem;
    }
}


@media screen and (max-width:500px) {
    .container{
        padding: 0rem 0.5rem 0 0.5rem;
    }
}