.text{
    font-size: 1.5rem;
    font-family: 'Signika Negative',sans-serif;
    text-align: center;
    color: #1F1C3A;
}

.text_vs{
    font-size: 4rem;
    font-family: 'Signika Negative',sans-serif;
    text-align: center;
    color: #1F1C3A;
}