.quizes_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
}

.quiz_container{
    cursor: pointer;
    /* background-color: #6A5AE0; */
    background-color: #1F1C3A;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
}

.quiz_card{
    background-color: black;
    padding: 0.4rem;
}

.quiz_image{
    width: 10rem;
    height: 8rem;
}
.image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.quiz_data_container{
    width: 80%;
    /* height: 100%; */
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    overflow: hidden;
    position: relative; 
}

.quiz_description p::after {
    content: '...';
    position: absolute;
    right: 0; 
    background-color: #1F1C3A; 
    padding: 0 5px; 
}

.quiz_title h3{
    /* color: #f0f0f0; */
    color: #fff;
    font-size: 1.8rem;
    font-family: 'Lato',sans-serif;
}

.quiz_description p{
    color: rgb(226 232 240);
    font-size: 1.5rem;
    font-family: 'Lato',sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin: 0; /* Remove any default margins for better styling */
}

.icon{
    position: absolute;
    right: 1%;
    top: 50%;
    transform: translate(-1%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    width:3rem;
    height: 3rem;
    border-radius: 50%;
    transition: all 0.3s;
    cursor: pointer;
}
.next_icon{
    font-size: 1.5rem;
    color: white;
}
.icon:hover{
    background-color: #000000;
}
.icon:active{
    transform: scale(1.1);
}